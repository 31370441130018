import React from 'react'
import { Body1 } from '../value/Styled';
import { useTranslation } from 'react-i18next';
import styles from './Certificates.module.css'

const PEFC_text = () => {
    const { t } = useTranslation();
    return (
        <Body1 className={styles.certyBody}>
            <b>PEFC</b><p/> {t('Certificates.PEFC')}  
            <p/>{t('Certificates.PEFC1')} 
            <p/>{t('Certificates.PEFC2')} 
            <p/>{t('Certificates.PEFC3')} 
            <p/>{t('Certificates.PEFC4')} 
            <p/>{t('Certificates.PEFC5')} 
            <p/><p/>{t('Certificates.PEFC6')} 
        </Body1>
    )
}
export default PEFC_text;