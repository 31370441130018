import React from 'react';
import { Body1 } from '../value/Styled';
import { useTranslation } from 'react-i18next';
import styles from './Certificates.module.css'

const Swiad_text = () => {
    const { t } = useTranslation();
    return (
        <Body1 className={styles.certyBody}>
            <b> {t('Certificates.SwiadTitle')}  </b>  <p/> {t('Certificates.Swiad')}  
            <p/>{t('Certificates.Swiad1')} 
            <p/>{t('Certificates.Swiad2')} 
            <p/>{t('Certificates.Swiad3')} 
            <p/>{t('Certificates.Swiad4')} 
            <p/>{t('Certificates.Swiad5')} 
            <p/>{t('Certificates.Swiad6')} 
            <p/><p/>{t('Certificates.Swiad7')} 
        </Body1>
    )
}
export default Swiad_text;