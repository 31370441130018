import React from 'react';
import { Body1 } from '../value/Styled';
import { useTranslation } from 'react-i18next';
import styles from './Certificates.module.css';

const FSC_text = () => {
    const { t } = useTranslation();
    return (
        <Body1 className={styles.certyBody}>
        <b>FSC</b><sup>®</sup> <p/> {t('Certificates.FSC')}  
            <p/>{t('Certificates.FSC1')} 
            <p/>{t('Certificates.FSC2')} 
            <p/>{t('Certificates.FSC3')} 
            <p/>{t('Certificates.FSC4')} 
            <p/>{t('Certificates.FSC5')} 
            <p/>{t('Certificates.FSC6')} 
            <p/><p/>{t('Certificates.FSC7')} 
        </Body1>
    )
}
export default FSC_text;