import React from 'react';
import { Body1 } from '../value/Styled';
import { useTranslation } from 'react-i18next';
import styles from './Certificates.module.css'

const ENplus_text = () => {
    const { t } = useTranslation();
    return (
        <Body1 className={styles.certyBody}>
            <b>ENplus</b><sup>®</sup><p/> {t('Certificates.ENplus')}  
            <p/>{t('Certificates.ENplus1')} 
            <p/>{t('Certificates.ENplus2')} 
            <p/>{t('Certificates.ENplus3')} 
            <p/>{t('Certificates.ENplus4')} 
            <p/>{t('Certificates.ENplus5')} 
            <p/><p/>{t('Certificates.ENplus6')} 
        </Body1>
    )
}
export default ENplus_text;